<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_partner_doctor") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              size="medium"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.phone_number.title" prop="phone_number">
            <crm-input
              v-model="form.phone_number"
              :maskFormat="'tel'"
              autocomplete="off"
              size="medium"
            ></crm-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
            :label="columns.partner_clinic_id.title"
            prop="partner_clinic"
          >
            <el-select
              :placeholder="$t('message.partner_clinic')"
              v-model="form.partner_clinic_ids"
              :multiple="true"
              clearable
              filterable
            >
              <el-option
                v-for="item in clinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                size="medium"
              >
              </el-option>
            </el-select>

            <!-- <el-input
              v-model="form.partner_clinic_id"
              autocomplete="off"
            ></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>
      <div class="block">
        <el-tree
          class="filter-tree"
          :data="data"
          show-checkbox
          node-key="id"
          :filter-node-method="filterNode"
          ref="items"
          @node-click="handleNodeClick"
          :expand-on-click-node="true"
        >
          <span
            :style="`background: ${data.color}`"
            class="custom-tree-node"
            slot-scope="{ node, data }"
          >
            <span class="aaa my-tree-khan">
              <div>{{ data.name }}</div>
              <select
                :placeholder="$t('message.class')"
                v-model="data.type_raise_amount"
                :disabled="!node.checked"
                size="small"
                @change="check(node)"
                class="my-kh ml-3"
                style="border: 1px solid #dcdfe6; border-radius: 4px"
              >
                <option
                  v-for="item in classes"
                  :key="item.name"
                  :label="item.name"
                  :value="item.type"
                ></option>
              </select>
              <input
                :disabled="!data.type_raise_amount"
                v-model="data.amount"
                :placeholder="'sum'"
                ref="'data_amount'+ node.id"
                class="my-kh el-input__inner2"
                size="small"
              />
            </span>
          </span>
        </el-tree>
      </div>
      <!-- end row -->
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child-new";
import { i18n } from "@/utils/i18n";
export default {
  mixins: [form, drawerChild],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      classes: [
         {
          type: "percent",
          name: i18n.t("message.percentage"),
        },
        {
          type: "amount",
          name: i18n.t("message.amount"),
        },
      ],
      data: [],
      services: [],
    };
  },

  created() {},

  computed: {
    ...mapGetters({
      clinics: "partnerClinic/inventory_all",
      model: "partnerDoctor/model",
      rules: "partnerDoctor/rules",
      columns: "partnerDoctor/columns",
    }),
  },

  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    const query = { relation: true };
    this.servicesRealtionList(query)
      .then((res) => {
        this.data = res.data.services;
      })
      .catch((err) => {});

    this.updatePartnerClinics();

    function getFields(input, field) {
      var output = [];
      for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    }
  },
  methods: {
    ...mapActions({
      save: "partnerDoctor/update",
      updatePartnerClinics: "partnerClinic/inventoryAll",
      servicesRealtionList: "services/relationList",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    async afterOpened() {
      if (this.form.image) {
        this.form.image = this.url + this.form.image.image;
      }
      const query = { relation: true };
      await this.servicesRealtionList(query)
        .then((res) => {
          this.data = res.data.services;
        })
        .catch((err) => {});
      await this.form.doctor_service.forEach((serv) => {
        this.data.forEach((child) => {
          if (child.children.find((x) => x.id == serv.service_id)) {
            console.log(child.children.find((x) => x.id == serv.service_id));
            child.children.find((x) => x.id == serv.service_id).amount =
              serv.amount;
            child.children.find((x) => x.id == serv.service_id).type_raise_amount =
              serv.type_raise_amount;
          }
        });

        if (this.data.find((x) => x.id == serv.service_id)) {
          this.data.find((x) => x.id == serv.service_id).amount = serv.amount;
          this.data.find((x) => x.id == serv.service_id).type_raise_amount =
            serv.type_raise_amount;
        }

        if (this.$refs.items) {
          if (
            this.$refs.items.root.childNodes.find(
              (x) => x.data.id == serv.service_id
            )
          )
            this.$refs.items.root.childNodes.find(
              (x) => x.data.id == serv.service_id
            ).checked = true;

          this.$refs.items.root.childNodes.forEach((item) => {
            if (item.childNodes.find((x) => x.data.id == serv.service_id))
              item.childNodes.find(
                (x) => x.data.id == serv.service_id
              ).checked = true;
          });
        }
      });
    },
    afterClosed() {
      this.resetForm("form");
      this.$refs.items.setCheckedNodes([]);
      this.data = [];
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    check(node) {
      console.log(node);
    },
    submitTransaction(close = true) {
      console.log(this.$refs.items.getCheckedNodes());
      console.log(this.$refs.items.getCheckedKeys());
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.services = this.$refs.items.getCheckedNodes();
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
              this.resetForm("form");
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.data = [];
      this.close();
      // this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
<style>
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>